<template>
  <div class="profile-wrapper emb-card pa-4">
    <div class="py-4 d-flex justify-space-between">
      <v-btn :href="'/lotteries/megamillions?customer_id=' + customer_id"
        >Add Megamillions</v-btn
      >
      <v-btn :href="'/lotteries/powerball?customer_id=' + customer_id"
        >Add Powerball</v-btn
      >
    </div>
    <div class="pt-6 d-flex justify-space-between">
      <v-btn @click="delete_user">
        <div v-if="!deleting_user">
          <v-icon>mdi-delete-forever</v-icon>Delete User
        </div>
        <div v-else>
          <v-progress-circular indeterminate size="16"></v-progress-circular>
        </div>
      </v-btn>
      <v-btn @click="simulate_user">
        <div v-if="!loading_simulate_user">
          Simulate User
        </div>
        <div v-else>
          <v-progress-circular indeterminate size="16"></v-progress-circular>
        </div>
      </v-btn>
    
    </div>
    <div class="pt-6 d-flex justify-space-between">
      <v-btn @click="ban_user">
        <div v-if="!ban_user_progress">
          <v-icon>mdi-account-cancel</v-icon>Ban User
        </div>
        <div v-else>
          <v-progress-circular indeterminate size="16"></v-progress-circular>
        </div>
      </v-btn>    
    </div>
    <emb-delete-confirmation
      ref="deleteUserConfirmationDialog"
      message="Are you sure you want to delete this user?"
      @onConfirm="on_delete_user_confirmation"
    >
    </emb-delete-confirmation>
    <emb-delete-confirmation
      ref="banUserConfirmationDialog"
      message="Are you sure you want to ban this user?"
      @onConfirm="on_ban_user_confirmation"
    >
    </emb-delete-confirmation>
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
export default {
  data() {
    return {
      customer_id: "",
      deleting_user: false,
      ban_user_progress: false,
      loading_simulate_user: false,
    };
  },
  beforeMount() {
    this.customer_id = this.$route.params.id;
  },
  methods: {
    simulate_user() {
      this.loading_simulate_user = true;
      this.$store.dispatch("simulate_user", this.customer_id).then(() => {
        // console.log("done");
        this.loading_simulate_user = false;
      });
    },
    delete_user() {
      this.$refs.deleteUserConfirmationDialog.openDialog();
    },
    ban_user() {
      this.$refs.banUserConfirmationDialog.openDialog();
    },
    on_delete_user_confirmation() {
      this.$refs.deleteUserConfirmationDialog.close();
      this.deleting_user = true;
      const deleteUserFunc = httpsCallable(getFunctions(), "deleteUser");
      deleteUserFunc({ user: this.customer_id })
        .then(() => {
          this.deleting_user = false;
          this.$router.replace("/admin-panel/search-user-info");
        })
        .catch(() => {
          this.deleting_user = false;
        });
    },
    on_ban_user_confirmation() {
      this.$refs.banUserConfirmationDialog.close();
      this.ban_user_progress = true;
      const banUserFunc = httpsCallable(getFunctions(), "banUser");
      banUserFunc({ user: this.customer_id })
        .then(() => {
          this.ban_user_progress = false;
          this.$router.replace("/admin-panel/search-user-info");
        })
        .catch(() => {
          this.ban_user_progress = false;
        });
    },
  },
};
</script>